/* eslint-disable */
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import CurrencyInput from 'react-currency-input';

import {
  unidadeMedidas,
  getCampoObrigatorioClassName,
} from '../../../../_constants';

const Tamanhos = (props) => {
  let solicitacao = props.solicitacao;
  let material = props.material;
  let layoutArte = props.layoutArte;
  let solicitacaoDados = solicitacao.solicitacaoDados || {};
  let updateSolicitacaoDados = props.updateSolicitacaoDados;
  let tamanhoUnico = props.tamanhoUnico;
  let obrigatorio = props.obrigatorio;

  let unidadeMedida = material.unidadeMedida;

  const [solicitacaoTamanhos, setSolicitacaoTamanhos] = useState(
    (solicitacao.solicitacaoTamanhos || []).length > 0
      ? solicitacao.solicitacaoTamanhos
      : [{}]
  );

  const updateSolicitacaoTamanhos = () => {
    solicitacao.solicitacaoTamanhos = [...solicitacaoTamanhos];
    setSolicitacaoTamanhos(solicitacao.solicitacaoTamanhos);
    //updateSolicitacaoDados(solicitacaoDados);
  };

  const handleChangeCheckbox = (checked, tamanho) => {
    if (checked) {
      const valorNaLista =
        solicitacaoTamanhos &&
        solicitacaoTamanhos.filter(
          (valorAtual) =>
            valorAtual.largura === tamanho.largura &&
            valorAtual.altura === tamanho.altura
        );

      if (!valorNaLista || !valorNaLista.length) {
        const novaLista = !solicitacaoTamanhos
          ? []
          : solicitacaoTamanhos.filter((item) => {
              return (
                item.largura &&
                item.altura &&
                item.largura !== 0 &&
                item.altura !== 0
              );
            });

        setSolicitacaoTamanhos([
          ...novaLista,
          { tamanhoFixo: true, ...tamanho },
        ]);
        solicitacao.solicitacaoTamanhos = [
          ...novaLista,
          { tamanhoFixo: true, ...tamanho },
        ];
        updateSolicitacaoDados(solicitacaoDados);
      }
    } else {
      const removerMaterialSelecionado = solicitacaoTamanhos.filter(
        (valorAtual) =>
          !(
            valorAtual.largura === tamanho.largura &&
            valorAtual.altura === tamanho.altura
          )
      );
      setSolicitacaoTamanhos(removerMaterialSelecionado);
      solicitacao.solicitacaoTamanhos = removerMaterialSelecionado;
      updateSolicitacaoDados(solicitacaoDados);
    }
  };

  const removerTamanho = (index) => {
    solicitacaoTamanhos.splice(index, 1);
    updateSolicitacaoTamanhos();
  };

  const getErrorValorMinimo = (valor, minimo) => {
    if (!valor || !minimo) return '';

    let valorFloat = parseFloat(valor.toString().replace(',', '.'));
    let minimoFloat = parseFloat(minimo.toString().replace(',', '.'));

    if (valorFloat < minimoFloat) return 'tamanho-error';

    return '';
  };

  const renderTamanho = (solicitacaoTamanho, index) => {
    if (unidadeMedida === unidadeMedidas.cm) {
      return (
        <div
          className="col-lg-12"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0',
            backgroundColor: '#e1e3e5',
          }}
        >
          <div
            className="row"
            style={{ justifyContent: 'flex-end', padding: '0px 25px 0 0' }}
          >
            <span style={{ cursor: 'pointer', alignSelf: 'flex-end' }}>
              {!tamanhoUnico && (
                <FaTimes onClick={() => removerTamanho(index)} />
              )}
            </span>
          </div>

          <div className="row  m-0 mb-2">
            <div className="col-lg-6 ">
              <label className="mb-0">
                Largura (cm){' '}
                {layoutArte?.larguraMinima > 0 && (
                  <i className="text-primary">
                    mínimo: {layoutArte.larguraMinima}
                  </i>
                )}
              </label>
              <CurrencyInput
                disabled={!!solicitacaoTamanho.tamanhoFixo}
                decimalSeparator=","
                thousandSeparator=""
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoTamanho.largura,
                  obrigatorio
                )} ${getErrorValorMinimo(
                  solicitacaoTamanho.largura,
                  layoutArte?.larguraMinima
                )}`}
                value={solicitacaoTamanho.largura || ''}
                onChangeEvent={(event) => {
                  solicitacaoTamanho.largura = event.target.value;
                  updateSolicitacaoTamanhos();
                }}
              />
            </div>
            <div className="col-lg-6 ">
              <label className="mb-0">
                Altura (cm){' '}
                {layoutArte?.alturaMinima > 0 && (
                  <i className="text-primary">
                    mínimo: {layoutArte.alturaMinima}
                  </i>
                )}
              </label>
              <CurrencyInput
                disabled={!!solicitacaoTamanho.tamanhoFixo}
                decimalSeparator=","
                thousandSeparator=""
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoTamanho.altura,
                  obrigatorio
                )} ${getErrorValorMinimo(
                  solicitacaoTamanho.altura,
                  layoutArte?.alturaMinima
                )}`}
                value={solicitacaoTamanho.altura || ''}
                onChangeEvent={(event) => {
                  solicitacaoTamanho.altura = event.target.value;
                  updateSolicitacaoTamanhos();
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    //pixel nao mostra as casas decimais
    else {
      return (
        <div
          className="col-lg-12"
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0',
            backgroundColor: '#e1e3e5',
          }}
        >
          <div
            className="row   "
            style={{ justifyContent: 'flex-end', padding: '0px 25px 0 0' }}
          >
            <span style={{ cursor: 'pointer', alignSelf: 'flex-end' }}>
              {!tamanhoUnico && (
                <FaTimes onClick={() => removerTamanho(index)} />
              )}
            </span>
          </div>

          <div className=" row " style={{ marginBottom: '15px' }}>
            <div className="col-lg-6 ">
              <label>
                Largura (px){' '}
                {layoutArte?.larguraMinima > 0 && (
                  <i className="text-primary">
                    mínimo: {layoutArte.larguraMinima}
                  </i>
                )}
              </label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoTamanho.largura,
                  obrigatorio
                )} ${getErrorValorMinimo(
                  solicitacaoTamanho.largura,
                  layoutArte?.larguraMinima
                )}`}
                value={solicitacaoTamanho.largura || ''}
                onChange={(event) => {
                  solicitacaoTamanho.largura = event.target.value;
                  updateSolicitacaoTamanhos();
                }}
              />
            </div>
            <div className="col-lg-6 ">
              <label>
                Altura (px){' '}
                {layoutArte?.alturaMinima > 0 && (
                  <i className="text-primary">
                    mínimo: {layoutArte.alturaMinima}
                  </i>
                )}
              </label>
              <input
                type="text"
                className={`form-control ${getCampoObrigatorioClassName(
                  solicitacaoTamanho.altura,
                  obrigatorio
                )} ${getErrorValorMinimo(
                  solicitacaoTamanho.altura,
                  layoutArte?.alturaMinima
                )}`}
                value={solicitacaoTamanho.altura || ''}
                onChange={(event) => {
                  solicitacaoTamanho.altura = event.target.value;
                  updateSolicitacaoTamanhos();
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="col-lg-12 mb-4">
      {material.materialComunicacaoTamanhos.length > 0 ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <p>Tamanhos:</p>
            </div>
            <br />
            {material.materialComunicacaoTamanhos.map((tamanho) => {
              return (
                <div
                  key={tamanho.materialComunicacaoTamanhoId}
                  className="col-md-4"
                >
                  <label>
                    <input
                      onChange={(e) =>
                        handleChangeCheckbox(e.target.checked, tamanho)
                      }
                      checked={
                        (solicitacaoTamanhos || []).findIndex(
                          (el) =>
                            el.largura === tamanho.largura &&
                            el.altura === tamanho.altura
                        ) !== -1
                      }
                      type="checkbox"
                      className="mr-2"
                      id={tamanho.materialComunicacaoTamanhoId}
                    />
                    {tamanho.descricao}
                  </label>
                </div>
              );
            })}
          </div>
          {solicitacaoTamanhos.map((solicitacaoTamanho, index) => {
            return (
              <div key={index} className="mb-2">
                {renderTamanho(solicitacaoTamanho, index)}
              </div>
            );
          })}
        </>
      ) : (
        solicitacaoTamanhos.map((solicitacaoTamanho, index) => {
          return (
            <div key={index} className="mb-2">
              {renderTamanho(solicitacaoTamanho, index)}
            </div>
          );
        })
      )}

      {!tamanhoUnico && (
        <button
          type="button"
          className="btn btn-link"
          onClick={() => {
            solicitacaoTamanhos.push({});
            updateSolicitacaoTamanhos();
          }}
        >
          incluir nova medida
        </button>
      )}
    </div>
  );
};

export default Tamanhos;

/* eslint-disable */

import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const msalConfig = {
    auth: {
        clientId: '4bc4061f-b360-4e6b-8461-ef406554f65c', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/27951710-ebb9-4efb-a4a5-48e460822d4b',
        clientSecret: 'V2n8Q~es5MmeR6OpX.BokuCd4nF9dzEZiAStJb6o',
        redirectUri: "https://maisqueimagensbrf.com.br/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: "https://maisqueimagensbrf.com.br/", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        validateAuthority: true,
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: "https://maisqueimagensbrf.com.br/"
}
const authenticationParameters = {
    scopes: [
        'user.read'
    ]
}
export const authProvider = new MsalAuthProvider(msalConfig, authenticationParameters, options);

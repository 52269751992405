/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { FaFileUpload, FaFileDownload, FaImages } from 'react-icons/fa';

import './styles.scss';
import history from 'routes/history';
import api from 'api';
import {
  GET_SELECT_LIST_CANAL,
  GET_SELECT_LIST_MATERIAL_COMUNICACAO,
  GET_SELECT_LIST_MES,
  GET_SELECT_LIST_REGIONAL,
  GET_RELATORIO_ENVIO_BYTES,
  GET_PPTX,
  GET_PLANO_ATUAL,
} from 'services/OnOff';

import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';
import getDataAtualOnOff from 'util/onoff_data_atual';

const qs = require('qs');

const OnOffRelatorios = (props) => {
  const { showLoader } = useContext(Context);

  const [errorMessage, setErrorMessage] = useState('');
  const [dadosFiltro, setDadosFiltro] = useState({});
  const [regionalList, setRegionalList] = useState([]);
  const [canalList, setCanalList] = useState([]);
  const [mesList, setMesList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [mesAtual, setMesAtual] = useState('');
  const [planoFinalizado, setPlanoFinalizado] = useState(true);

  useEffect(() => {
    (async () => {
      showLoader(true);
      setMesAtual(getDataAtualOnOff());
      const planoAtual = await GET_PLANO_ATUAL();

      if (
        200 === planoAtual.code &&
        (planoAtual.value == null || planoAtual.value.dataFechamento === null)
      ) {
        setPlanoFinalizado(false);
      }

      const resultRegionais = await GET_SELECT_LIST_REGIONAL();
      setRegionalList(resultRegionais.value || []);

      const resultCanais = await GET_SELECT_LIST_CANAL();
      setCanalList(resultCanais.value || []);

      const resultMes = await GET_SELECT_LIST_MES();
      setMesList(resultMes.value || []);

      await setMaterialListAsync('');

      showLoader(false);
    })();
  }, []);

  async function setMaterialListAsync(plano) {
    const resultMaterial = await GET_SELECT_LIST_MATERIAL_COMUNICACAO({
      plano: plano,
    });
    setMaterialList(resultMaterial.value || []);
  }

  async function onChangeWithName(value, name) {
    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });
  }

  async function onPlanoChange(value, name) {
    showLoader(true);

    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });

    await setMaterialListAsync(value == null ? '' : value);

    showLoader(false);
  }

  function onChangeInput(event) {
    const { value, name } = event.target;

    setDadosFiltro({
      ...dadosFiltro,
      [name]: value,
    });
  }

  function goToUploads(route) {
    history.push(`/adicionar-midia-on-off/`);
  }

  function goToEnviadas(route) {
    history.push(`/on-off-midias-enviadas/`);
  }

  async function downloadPPTX() {

    if (!dadosFiltro || !dadosFiltro.mesReferencia) {
      setErrorMessage('Selecione o Mês de Veiculação.');
      return;
    }

    showLoader(true);

    let data = await GET_PPTX(dadosFiltro);

    if (data && data.value) {
      let today = new Date();
      let date =
        today.getFullYear() +
        '_' +
        (today.getMonth() + 1) +
        '_' +
        today.getDate();
      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });

      let fileName = 'book_plano_on_of_' + date + '.pptx';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      showLoader(false);
    } else {
      showLoader(false);
    }
  }
  async function downloadExcel() {
    showLoader(true);
    let result = await GET_RELATORIO_ENVIO_BYTES(dadosFiltro);

    if (result && result.value) {
      let today = new Date();
      let date =
        today.getFullYear() +
        '_' +
        (today.getMonth() + 1) +
        '_' +
        today.getDate();
      let file = _base64ToByteArray(result.value);
      let blob = new Blob([file], { type: 'arraybuffer' });

      let fileName = 'book_plano_on_of_' + date + '.xlsx';
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();

      showLoader(false);
    } else {
      showLoader(false);
    }
  }

  function _base64ToByteArray(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  return (
    <div>
      <h1 className="onoff-title">RELATÓRIO PLANO ON&OFF</h1>
      {!planoFinalizado && (
        <div className="on-off-nav-btn text-center">
          <Button className="onoff-button" disabled={''} onClick={goToUploads}>
            <FaFileUpload size="28" />
            &nbsp;&nbsp; FAZER UPLOAD DE MÍDIAS
          </Button>
          <Button className="onoff-button" disabled={''} onClick={goToEnviadas}>
            <FaImages size="28" />
            &nbsp;&nbsp; MÍDIAS ENVIADAS EM {mesAtual}
          </Button>
        </div>
      )}
      {planoFinalizado && (
        <div className="text-center">
          <label>Você já concluiu o envio de mídias deste mês.</label>
        </div>
      )}
      <div className="d-flex justify-content-center">
        <div className="onoff-content">
          {errorMessage && (
            <Alert
              variant="danger"
              onClose={() => setErrorMessage('')}
              dismissible
            >
              {errorMessage}
            </Alert>
          )}
          <Row>
            <Col lg-6="true">
              <label>Regional</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.regionalId}
                  name="regionalId"
                  onChange={onChangeWithName}
                  options={regionalList}
                />
              </div>

              <label>Canal</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.canalId}
                  name="canalId"
                  onChange={onChangeWithName}
                  options={canalList}
                />
              </div>

              <label>Rede</label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  name="rede"
                  onChange={onChangeInput}
                  value={dadosFiltro.rede}
                ></input>
              </div>
            </Col>

            <Col lg-6="true">
              <label>Mês de Veiculação</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.mesReferencia}
                  name="mesReferencia"
                  onChange={onChangeWithName}
                  options={mesList}
                />
              </div>
              <label>Plano</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.plano}
                  name="plano"
                  onChange={onPlanoChange}
                  options={[
                    { value: 'ON', label: 'ON' },
                    { value: 'OFF', label: 'OFF' },
                  ]}
                />
              </div>

              <label>Tipo de Material</label>
              <div>
                <SingleSelect
                  isClearable={true}
                  value={dadosFiltro.materialId}
                  name="materialId"
                  onChange={onChangeWithName}
                  options={materialList}
                />
              </div>
            </Col>
          </Row>
          <NavButtons />
        </div>
      </div>
    </div>
  );

  function NavButtons() {
    return (
      <div className="on-off-nav-btn ">
        <Row>
          <Col lg-6="true" className="text-right">
            <Button
              className="onoff-button"
              disabled={''}
              onClick={() => {
                downloadPPTX();
              }}
            >
              <FaFileDownload size="28" />
              &nbsp;&nbsp; BAIXAR BOOK PPT
            </Button>
          </Col>
          <Col lg-6="true"  className="text-left">
            <Button
              className="onoff-button"
              disabled={''}
              onClick={() => {
                downloadExcel();
              }}
            >
              {' '}
              <FaFileDownload size="28" />
              &nbsp;&nbsp; BAIXAR RELATÓRIO DE UPLOAD
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
};

export default OnOffRelatorios;

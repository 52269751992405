import api from 'api';

const PATH = '/ExtratoSolicitacaoCusto';

const GET_EXTRATO_CUSTO_BYTES = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-extrato-custo-em-bytes-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

const GET_EXTRATO_CUSTO_CODIGO_BYTES = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-extrato-codigo-em-bytes-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

const GET_EXTRATO_CUSTO_ARQUIVO = async (filtros) => {
  const { data } = await api.get(
    `${PATH}/get-extrato-custo-em-arquivo-por-filtro`,
    {
      params: filtros,
    }
  );
  return data;
};

export {
  GET_EXTRATO_CUSTO_BYTES,
  GET_EXTRATO_CUSTO_CODIGO_BYTES,
  GET_EXTRATO_CUSTO_ARQUIVO,
};

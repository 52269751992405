import '../styles.scss';
import React from 'react';
import SemDados from '../SemDados';

const VisaoBrasil = (props) => {
  const { data } = props;
  const titulo = 'VISÃO BRASIL';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itensNome = data.map((item) => {
    const str = item.nome;
    const mes = str.substring(0, 3);
    const ano = str.substring(str.indexOf('-') + 1).trim();
    return `${mes} - ${ano}`;
  });

  const itensQtde = data.map((item) => {
    return item.totalSolicitacao;
  });

  const itensQtdeArte = data.map((item) => {
    return item.totalArte;
  });

  const itensQtdeAprovados = data.map((item) => {
    return item.totalSolicitacaoAprovado;
  });

  const itensQtdeEncarte = data.map((item) => {
    return item.totalSolicitacaoEncarte;
  });

  const itensQtdeEncarteImpresso = data.map((item) => {
    return item.totalEncarteImpresso;
  });

  const itensVerbaRegional = data.map((item) => {
    return item.totalVerbaRegional;
  });

  const itensVerbaUtilizada = data.map((item) => {
    return item.totalVerbaUtilizada;
  });

  const itensPorcentagemVerba = data.map((item) => {
    return item.porcentagemVerba;
  });

  const itensVerbaDisponivel = data.map((item) => {
    return item.totalVerbaDisponivel;
  });

  return (
    <div className="grafico" style={{ overflowY: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr>
              <th
                className="fixed-header fixed-col-1"
                style={{ width: '250px' }}
                aria-label="header"
                key="he"
              />
              {itensNome.map((item, index) => {
                return (
                  <th
                    className="fixed-header"
                    style={{ width: `100px` }}
                    key={`h${itensNome[index]}`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td key="ts" className="fixed-col-1">
                Total de solicitações criadas
              </td>
              {itensQtde.map((item, index) => {
                return (
                  <td key={`ts${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="ta" className="fixed-col-1">
                Total solicitações aprovadas
              </td>
              {itensQtdeAprovados.map((item, index) => {
                return (
                  <td key={`ta${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="aa" className="fixed-col-1">
                Arte Aprovada
              </td>
              {itensQtdeArte.map((item, index) => {
                return (
                  <td key={`aa${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="te" className="fixed-col-1">
                Total Solicitações Encarte
              </td>
              {itensQtdeEncarte.map((item, index) => {
                return (
                  <td key={`te${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="ti" className="fixed-col-1">
                Total de Encartes Impressos
              </td>
              {itensQtdeEncarteImpresso.map((item, index) => {
                return (
                  <td key={`ti${itensNome[index]}`}>
                    {item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="vr" className="fixed-col-1">
                Verba por Regional
              </td>
              {itensVerbaRegional.map((item, index) => {
                return (
                  <td key={`vr${itensNome[index]}`}>
                    R${item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="vb" className="fixed-col-1">
                Verba Utilizada Brasil
              </td>
              {itensVerbaUtilizada.map((item, index) => {
                return (
                  <td key={`vb${itensNome[index]}`}>
                    R${item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td key="vu" className="fixed-col-1">
                % Verba Utilizada
              </td>
              {itensPorcentagemVerba.map((item, index) => {
                return <td key={`vu${itensNome[index]}`}>{item}%</td>;
              })}
            </tr>
            <tr>
              <td key="vd" className="fixed-col-1">
                Verba Disponível
              </td>
              {itensVerbaDisponivel.map((item, index) => {
                return (
                  <td key={`vd${itensNome[index]}`}>
                    R${item.toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VisaoBrasil;

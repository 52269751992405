import React from 'react';

import HeaderApp from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import PageTitle from 'components/_base/PageTitle';

import ContentHistoricoSolicitacoes from './ContentHistoricoSolicitacoes';

const App = () => {
  return (
    <>
      <HeaderApp />
      <PageTitle title="Histórico de solicitações" />
      <hr />
      <ContentHistoricoSolicitacoes />
      <Bottom />
    </>
  );
};

export default App;

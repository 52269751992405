/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable */

import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaAngleLeft, FaAngleRight, FaFilter, FaEraser } from 'react-icons/fa';

import Header from 'components/Header/HeaderApp';
import Bottom from 'components/Bottom';
import { GET_LIST_CHAMADO_POR_USUARIO } from 'services/Chamado';
import SingleSelect from 'components/_base/SelectSingle';
import TitlePages from 'components/_base/TitlePages';
import { Context } from 'context/AuthContext';
import { dateFormat } from 'helpers/Date.Helper';
import history from 'routes/history';

import './styles.scss';

const ContentFollowCalled = () => {
  const userAuth = JSON.parse(localStorage.getItem('midias_brf'));
  const [chamados, setChamados] = useState([]);
  const [chamadosFiltrados, setChamadosFiltrados] = useState([]);
  const { showLoader } = useContext(Context);
  const [filter, setFilter] = useState({
    number: '',
    status: '',
  });

  useEffect(() => {
    (async () => {
      showLoader(true);

      const data = await GET_LIST_CHAMADO_POR_USUARIO(userAuth.userId);
      const dataAddStatus = data.value.map((chamadoItem) => {
        return {
          ...chamadoItem,
          status:
            chamadoItem.dataFinalizacao !== null
              ? 'Finalizado'
              : chamadoItem.chamadoRespostas[
                  chamadoItem.chamadoRespostas.length - 1
                ].usuario.usuarioId === chamadoItem.usuarioId
              ? 'Chamado enviado'
              : 'Chamado respondido',
        };
      });
      setChamados(dataAddStatus);
      showLoader(false);
    })();
  }, []);

  const visualizarClick = async (id) => {
    history.push(`/chat?id=${id}`);
  };

  const renderChamados = (chamadoItem, dateFormat, visualizarClick) => (
    <>
      <tr>
        <td
          style={{
            color: chamadoItem.dataFinalizacao !== null ? 'red' : null,
          }}
        >
          {chamadoItem.chamadoId}
        </td>
        <td
          style={{
            color: chamadoItem.dataFinalizacao !== null ? 'red' : null,
          }}
        >
          {chamadoItem.chamadoTipo.nome}
        </td>
        <td
          style={{
            color: chamadoItem.dataFinalizacao !== null ? 'red' : null,
          }}
        >
          {chamadoItem.usuario.nome}
        </td>
        <td
          style={{
            color: chamadoItem.dataFinalizacao !== null ? 'red' : null,
          }}
        >
          {dateFormat(chamadoItem.dataCriacao, true)}
        </td>
        <td
          style={{
            color: chamadoItem.dataFinalizacao !== null ? 'red' : null,
          }}
        >
          {chamadoItem.chamadoRespostas != null
            ? dateFormat(
                chamadoItem.chamadoRespostas[
                  chamadoItem.chamadoRespostas.length - 1
                ].data,
                true
              )
            : dateFormat(chamadoItem.dataCriacao, true)}
        </td>
        <td
          style={{
            color: chamadoItem.dataFinalizacao !== null ? 'red' : null,
          }}
        >
          {chamadoItem.dataFinalizacao !== null
            ? 'Finalizado'
            : chamadoItem.chamadoRespostas[
                chamadoItem.chamadoRespostas.length - 1
              ].usuario.usuarioId === chamadoItem.usuarioId
            ? 'Chamado enviado'
            : 'Chamado respondido '}
        </td>
        <td className="text-center">
          <Button
            style={{
              backgroundColor:
                chamadoItem.dataFinalizacao !== null
                  ? 'red'
                  : chamadoItem.chamadoRespostas[
                      chamadoItem.chamadoRespostas.length - 1
                    ].usuario.usuarioId === chamadoItem.usuarioId
                  ? null
                  : '#28a745 ',
              borderColor:
                chamadoItem.dataFinalizacao !== null
                  ? 'red'
                  : chamadoItem.chamadoRespostas[
                      chamadoItem.chamadoRespostas.length - 1
                    ].usuario.usuarioId === chamadoItem.usuarioId
                  ? null
                  : '#28a745 ',
            }}
            className="table-button"
            onClick={() => visualizarClick(chamadoItem.chamadoId)}
          >
            Visualizar
          </Button>
        </td>
      </tr>
    </>
  );
  return (
    <div className="wrapper-acompanhar-chamados">
      <TitlePages title="ACOMPANHAR CHAMADOS" />
      <div className="row filtro-chamados">
        <form className="col-12 pesquisa">
          <div className="row tipo">
            <label className="col-lg-3 col-form-label  " htmlFor="">
              Número do chamado
            </label>
            <div className="col-lg-9 ">
              <input
                type="text"
                className="form-control"
                value={filter.number}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    number: e.target.value.replace(/\D/g, ''),
                  })
                }
              />
            </div>
          </div>

          <div className="row tipo">
            <label className="col-lg-3 col-form-label numero" htmlFor="">
              Status
            </label>
            <div className="col-lg-9 ">
              <SingleSelect
                isClearable={true}
                className="form-control "
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    status: e,
                  })
                }
                options={[
                  { label: 'Finalizado', value: 'Finalizado' },
                  { label: 'Chamado enviado', value: 'Chamado enviado' },
                  { label: 'Chamado respondido', value: 'Chamado respondido' },
                ]}
                value={filter.status}
              />
            </div>
            <div className="row tipo mt-2">
              <div className="d-flex justify-content-start">
                <button
                  className="btn btn-primary btn-navigation mr-3  "
                  onClick={(e) => {
                    e.preventDefault();
                    if (filter.number && filter.status) {
                      const listaFiltrada = chamados.filter(
                        (item) =>
                          parseInt(item.chamadoId) ===
                            parseInt(filter.number) &&
                          item.status === filter.status
                      );
                      setChamadosFiltrados(listaFiltrada);
                      return;
                    }
                    if (filter.number || filter.status) {
                      const listaFiltrada = chamados.filter(
                        (item) =>
                          parseInt(item.chamadoId) ===
                            parseInt(filter.number) ||
                          item.status === filter.status
                      );
                      setChamadosFiltrados(listaFiltrada);
                      return;
                    }
                  }}
                >
                  <span>
                    <FaFilter size={18} />
                  </span>
                  <span> FILTRAR</span>
                </button>

                <button
                  className="btn bg-white text-primary border border-primary btn-navigation"
                  onClick={(e) => {
                    e.preventDefault();
                    setFilter({
                      number: '',
                      status: '',
                    });
                    setChamadosFiltrados([]);
                  }}
                >
                  <span>
                    <FaEraser size={22} />
                  </span>
                  <span> LIMPAR</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="table-query ">
        <div className="table-responsive">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Tipo</th>
                <th>Criador(a)</th>
                <th>Criado em</th>
                <th>Última Mensagem</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!chamadosFiltrados.length
                ? chamados.map((chamadoItem) =>
                    renderChamados(chamadoItem, dateFormat, visualizarClick)
                  )
                : chamadosFiltrados.map((chamadoItem) =>
                    renderChamados(chamadoItem, dateFormat, visualizarClick)
                  )}
            </tbody>
          </table>
        </div>
        <div className="voltar ">
          <Button className="btnVoltar" href="/atendimento">
            <FaAngleLeft />
            VOLTAR
          </Button>
        </div>
      </div>
    </div>
  );
};

const FollowCalled = () => {
  return (
    <>
      <Header />
      <ContentFollowCalled />
      <Bottom />
    </>
  );
};

export default FollowCalled;

/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import CurrencyInput from 'react-currency-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import Historico from '../../Status/ContentSolicitacaoFluxoStatus/Historico';

import {
  GET_REVISAO_MODEL,
  DEFIRNIR_TIPO_ARTE,
} from 'services/SolicitacaoFluxo';

const ContentSolicitacaoFluxoRevisao = () => {
  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});

  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_REVISAO_MODEL(solicitacaoId);
      console.log(responseModel);

      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function salvar(tipoArte) {
    (async () => {
      showLoader(true);

      const response = await DEFIRNIR_TIPO_ARTE(tipoArte);

      showLoader(false);
      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }
      setResultModel(response);
    })();
  }

  function renderResumoButton() {
    if (!model?.solicitacaoId || model?.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model?.solicitacaoId || model?.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model?.solicitacaoId || model?.solicitacaoId === 0) return null;

    return (
      <div className="text-center mt-4">
        {(model?.tipoArtes || []).map((tipoArte) => (
          <button
            type="button"
            className={`btn ${
              model.sigla?.length ? 'btn-secondary' : 'btn-primary'
            } mr-3 ${tipoArte.sigla === model.sigla ? 'btn-success' : ''}`}
            key={tipoArte.sigla}
            onClick={() => setModel({ ...model, sigla: tipoArte.sigla })}
          >
            Aprovar {tipoArte.descricao}
          </button>
        ))}
      </div>
    );
  }

  function renderForm() {
    if (!model?.solicitacaoId || model?.solicitacaoId === 0) return null;

    // if (!model.sigla?.length) return null;

    // let tipoArte = model.tipoArtes.find(x => x.sigla === model.sigla);

    let formModel = model.formModel || {};

    function calculateTotal() {
      return (formModel.tamanhos || []).reduce((total, tamanho) => {
        const valor = parseFloat(tamanho.valor) || 0;
        return total + valor;
      }, 0);
    }

    function handleInputChange(index, field, value) {
      formModel.tamanhos[index][field] = value;
      formModel.valorDiagramacao = calculateTotal();
      setModel({ ...model });
    }

    return (
      <>
        <hr />
        {/* {JSON.stringify(model)} */}
        <div className="row justify-content-center mb-4">
          <div className="col-lg-8 text-center">
            <h4></h4>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row mb-3">
              <div className="col-md-auto">
                <label>Valor Diagramação</label>
                <CurrencyInput
                  readOnly
                  decimalSeparator=","
                  thousandSeparator=""
                  className="form-control"
                  value={formModel.valorDiagramacao}
                  onChangeEvent={(e) => {
                    formModel.valorDiagramacao = e.target.value;
                    setModel({ ...model });
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg-12">
                <h4>Tamanhos</h4>

                <table className="table table-striped table-alterar-solicitacao mb-3">
                  <tbody>
                    <tr>
                      <th>Largura</th>
                      <th>Altura</th>
                      <th>Tipo de Arte</th>
                      <th>Valor</th>
                      <td></td>
                    </tr>
                    {(formModel.tamanhos || []).map((tamanho, index) => (
                      <tr key={index}>
                        <td>
                          <CurrencyInput
                            className="form-control"
                            decimalSeparator=","
                            thousandSeparator=""
                            value={tamanho.largura || ''}
                            onChangeEvent={(e) => {
                              formModel.tamanhos[index].largura =
                                e.target.value;
                              setModel({ ...model });
                            }}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control"
                            decimalSeparator=","
                            thousandSeparator=""
                            value={tamanho.altura || ''}
                            onChangeEvent={(e) => {
                              formModel.tamanhos[index].altura = e.target.value;
                              setModel({ ...model });
                            }}
                          />
                        </td>
                        <td>
                          <div
                            className="d-flex flex-row"
                            style={{ gap: '10px' }}
                          >
                            <div className="d-flex flex-row align-items-center">
                              <input
                                type="radio"
                                value="C"
                                checked={tamanho.arte === 'C'}
                                onChange={(e) => {
                                  tamanho.arte = e.target.value;
                                  tamanho.valor =
                                    formModel.valorDiagramacaoEntity
                                      .valorCriacao || 0;
                                  formModel.valorDiagramacao = calculateTotal();
                                  setModel({ ...model });
                                }}
                                style={{ width: '13px', height: '13px' }}
                              />
                              <label className="mb-0">Criação</label>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                              <input
                                type="radio"
                                value="A"
                                checked={tamanho.arte === 'A'}
                                onChange={(e) => {
                                  tamanho.arte = e.target.value;
                                  tamanho.valor =
                                    formModel.valorDiagramacaoEntity.valor || 0;
                                  formModel.valorDiagramacao = calculateTotal();
                                  setModel({ ...model });
                                }}
                                style={{ width: '13px', height: '13px' }}
                              />
                              <label className="mb-0">Adaptação</label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control"
                            decimalSeparator=","
                            thousandSeparator=""
                            value={tamanho.valor || ''}
                            onChangeEvent={(e) => {
                              formModel.tamanhos[index].valor = e.target.value;
                              formModel.valorDiagramacao = calculateTotal();
                              setModel({ ...model });
                            }}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-link"
                            onClick={() => {
                              if (formModel.tamanhos.length > 1) {
                                formModel.tamanhos.splice(index, 1);
                                formModel.valorDiagramacao = calculateTotal();
                                setModel({ ...model });
                              }
                            }}
                          >
                            Remover
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-link"
                  onClick={() => {
                    formModel.tamanhos = formModel.tamanhos || [];
                    formModel.tamanhos.push({});
                    setModel({ ...model });
                  }}
                >
                  Adicionar
                </button>
              </div>
            </div>

            {model?.imprimir ? (
              <>
                <div className="row mb-3">
                  <div className="col-lg-12">
                    <label className="d-block mb-0">Valor Impressao</label>
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator=""
                      className="form-control"
                      value={formModel.valorImpressao}
                      onChangeEvent={(e) => {
                        formModel.valorImpressao = e.target.value;
                        setModel({ ...model });
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-lg-12">
                    <label className="d-block mb-0">Valor Frete</label>
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator=""
                      className="form-control"
                      value={formModel.valorFrete}
                      onChangeEvent={(e) => {
                        formModel.valorFrete = e.target.value;
                        setModel({ ...model });
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  salvar(formModel);
                }}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  function setSomaTotal() {
    let total = 0;

    for (let i = 0; i < model.formModel.tamanhos.length; i++) {
      total += model.formModel.tamanhos[i].valor;
    }

    let formModel = model.formModel;
    formModel.valorDiagramacao = total;
    setModel({ ...model });
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={`Solicitação  ${solicitacaoId} - Aguardando Revisão`}
      />

      <div className="content-solicitacaofluxo">
        {/* {<pre>{JSON.stringify(resultModel, null, 2)}</pre>} */}

        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {/* {renderButtons()} */}

        {renderForm()}

        {renderHistory()}

        {renderModalSummary()}

        {/* {<pre className="text-left">{JSON.stringify(model, null, 2)}</pre>} */}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoRevisao;

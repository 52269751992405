/* eslint-disable */
import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Alert,
  Tabs,
  Tab,
  Sonnet,
  FormGroup,
  Table,
} from 'react-bootstrap';
import {
  FaHome,
  FaAngleRight,
  FaSearch,
  FaPeopleCarry,
  FaUserCheck,
  FaFileUpload,
  FaChevronRight,
  FaChevronLeft,
  FaPlusCircle,
  FaCalendarCheck,
  FaFileDownload,
  FaTelegramPlane,
  FaImages,
} from 'react-icons/fa';

import './styles.scss';
import history from 'routes/history';
import { GET_EXTRATO_CUSTO_BYTES, GET_EXTRATO_CUSTO_CODIGO_BYTES } from 'services/ExtratoSolicitacaoCusto';
import TitlePages from 'components/_base/TitlePages';
import LoaderAction from 'store/modules/loader/actions';
//import InputFileCustom from "../../../atoms/InputFile";
import {
  faCaretRight,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from 'context/AuthContext';
import SingleSelect from 'components/_base/SelectSingle';

const ExtratoDownloads = (props) => {
  const { showLoader } = useContext(Context);

  const regionais = props.regional[0]?.options;
  const filtro = props.filtro;

  const anoInicial = 2024;
  const mesInicial = 8;

  async function downloadExcel(regionalId, isFechamento) {
    showLoader(true);
    let paramFiltro = { ...filtro };
    paramFiltro.regionalId = regionalId;
    let data;
    if (isFechamento) {
      data = await GET_EXTRATO_CUSTO_CODIGO_BYTES(paramFiltro);
    } else {
      data = await GET_EXTRATO_CUSTO_BYTES(paramFiltro);
    }

    if (data && data.value) {
      let today = new Date();
      let date =
        today.getDate() +
        '_' +
        (today.getMonth() + 1) +
        '_' +
        today.getFullYear();
      let file = _base64ToByteArray(data.value);
      let blob = new Blob([file], { type: 'arraybuffer' });
      let fileName;
      if (isFechamento) {
        fileName = 'BRF.Rota_Relatorio_Fechamento_Mensal_' + date + '.xlsx';
      } else {
        fileName = 'BRF.Rota_Relatorio_Mensal_' + date + '.xlsx';
      }
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      let fileURL = window.URL.createObjectURL(blob);
      a.href = fileURL;
      a.download = fileName;
      a.click();
    }
    showLoader(false);
  }

  function _base64ToByteArray(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return bytes;
  }

  return filtro?.ano > 0 && filtro?.mes > 0 ? (
    <div className="extrato-downloads">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Regional</th>
            <th>Mês</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {regionais?.map((item, index) => {
            if (
              filtro?.regionalId == null ||
              filtro?.regionalId == '' ||
              filtro?.regionalId == item?.value
            ) {
              var regionalId = item?.value;
              if (regionalId == 1000) {
                regionalId = 0;
              }
              var mes = filtro?.mes;
              if (filtro?.mes < 10) {
                mes = '0' + mes;
              }
              return (
                <tr key={index}>
                  <td>{item?.label}</td>
                  <td>
                    {mes}/{filtro?.ano}
                  </td>
                  <td>
                    <Button
                      className="btn-extrato"
                      disabled={''}
                      onClick={() => {
                        downloadExcel(regionalId, false);
                      }}
                    >
                      {' '}
                      <FaFileDownload size="20" />
                      &nbsp; Extrato Plataforma
                    </Button>
                    {
                      // verifica se o mes e ano do filtro é menor que o mes e ano inicial
                      filtro?.ano > anoInicial || (filtro?.ano == anoInicial && filtro?.mes >= mesInicial) ? (
                        <Button
                          className="btn-extrato"
                          disabled={''}
                          onClick={() => {
                            downloadExcel(regionalId, true);
                          }}
                        >
                          {' '}
                          <FaFileDownload size="20" />
                          &nbsp; Relatório Fechamento
                        </Button>
                      ) : (
                        <div></div>
                      )
                    }
                  </td>
                </tr>
              );
            } else {
              return <div></div>;
            }
          })}
        </tbody>
      </Table>
    </div>
  ) : (
    <div></div>
  );
};

export default ExtratoDownloads;

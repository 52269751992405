/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperPlane,
  faPlus,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import SolicitacaoArquivos from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/SolicitacaoArquivos';
import Historico from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/Historico';

import {
  GET_ENVIAR_COMPROVANTE_MODEL,
  ENVIAR_COMPROVANTE
} from 'services/SolicitacaoFluxo';

const ContentEnviarComprovante = () => {

  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_ENVIAR_COMPROVANTE_MODEL(solicitacaoId);

      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function enviarComprovante() {
    showLoader(true);

    (async () => {
      const response = await ENVIAR_COMPROVANTE(model);

      if (response.value) {
        setModel({});
      }
      setResultModel(response);
      showLoader(false);

      window.scrollTo(0, 0);
    })();
  }

  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} omitirComprovante />;
  }
  function validateFiles(files) {
    const validExtensions = [".jpg", ".jpeg", ".png"];
    let invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split(".").pop().toLowerCase();

      if (!validExtensions.includes("." + extension)) {
        invalidFiles.push(file.name);
      }
    }

    return invalidFiles.length === 0;
  }


  function renderEnviarAprovacao() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <div className="row text-center">
          <div className="col-lg-12">
            <h4>
              Para finalizar a solicitação anexe abaixo o comprovante de publicação/exposição no PDV
              (ex: print do post publicado na rede social, foto do encarte disponível no PDV,
              material aplicado no PDV...)
            </h4>
          </div>

          <div className="col-lg-12 mt-2">
            <div className="mb-3">
              <label type="button" className="btn btn-primary">
                <input
                  type="file"
                  className="d-none"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) => {
                    const files = e.target.files;
                    const areFilesValid = validateFiles(files);

                    if (areFilesValid) {
                      setModel({
                        ...model,
                        files: [...files]
                      });
                      setErrorMessage(null);
                    } else {
                      e.target.value = null;
                      setErrorMessage("A extensão do arquivo é inválida, suba arquivos com as extensões .png ou .jpeg.");
                    }
                  }}
                />
                <FontAwesomeIcon icon={faPlus} /> Adicionar Arquivo
              </label>
              <p>{errorMessage}</p>

            </div>

            {
              (model.files || []).map((file, index) => (
                <div key={index} className="mb-2">
                  {file.name} <button type="button" className="btn btn-link" onClick={() => {
                    model.files.splice(index, 1);
                    setModel({ ...model });
                  }}><FontAwesomeIcon icon={faTimes} /></button>
                </div>
              ))
            }
          </div>

          <div className="col-lg-12 mt-2">
            <button className="btn btn-primary" onClick={enviarComprovante}>
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderComment() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <div className="row">
          <div className="col-lg-12">
            <textarea
              rows="7"
              className="form-control"
              value={model.observacao}
              onChange={(e) =>
                setModel({ ...model, observacao: e.target.value })
              }
            ></textarea>
          </div>
        </div>
      </>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <span key={index}>{error}</span>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={
          'Solicitação ' +
          solicitacaoId +
          (model && model.solicitacaoId && model.solicitacaoId > 0
            ? ' - Material entregue, aguardando comprovação.'
            : '')
        }
      />

      <div className="content-solicitacaofluxo text-center">
        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivos()}

        {renderEnviarAprovacao()}

        {/* {renderComment()} */}

        {renderHistory()}

        {renderModalSummary()}
      </div>
    </div>
  );
};

export default ContentEnviarComprovante;

/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { FormGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEraser } from '@fortawesome/free-solid-svg-icons';

import { Context } from 'context/AuthContext';
import Select from 'react-select';

import DatePicker from 'components/_base/DatePicker';
import { onlyIntegerNumber } from 'util/numberUtil';
import './styles.scss';

const Filter = (props) => {
  const { model, solicitantesList, redesList } = props
  const { getMaterialComunicacaoSelectList, getEtapaSelectList, getRegionalSelectListFilhas } =
    useContext(Context);
  const [materialList, setMaterialList] = useState([]);
  const [etapaList, setEtapaList] = useState([]);
  const [regionalList, setRegionalList] = useState([]);

  useEffect(() => {
    (async () => {
      setMaterialList(await getMaterialComunicacaoSelectList());
      setEtapaList(await getEtapaSelectList());
      setRegionalList(await getRegionalSelectListFilhas());
    })();
  }, []);

  function onChangeInput(event) {
    const { value, name } = event.target;

    props.setModel({
      ...model,
      [name]: value,
    });
  }

  async function onChangeWithName(value, name) {
    props.setModel({
      ...model,
      [name]: value,
    });
  }

  function onChangeMultiSelect(item, { name }) {
    const newValue =
      item && item.length !== null
        ? item.map((value) => {
          return parseInt(value.value, 10);
        })
        : '';

    props.setModel({
      ...model,
      [name]: newValue,
    });
  }

  // Render ---------------------------------------------------------------------------------------------------------------------
  return (
    <div className="filter-query">
      <form>

        {/* Solicitacao ----------------------------------------------------------------------------------------------------- */}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Nº da Solicitação
          </label>
          <div className="col-md-9">
            <input
              type="text"
              className="form-control"
              name="solicitacaoId"
              onChange={onChangeInput}
              value={model.solicitacaoId}
              onKeyPress={(event) => {
                onlyIntegerNumber(event);
              }}
            />
          </div>
        </FormGroup>

        {/* Material de comunicacao ----------------------------------------------------------------------------------------- */}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Material de Comunicação
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="materialComunicacaoIds"
              options={materialList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.materialComunicacaoIds &&
                model.materialComunicacaoIds.map((value) => {
                  let itemCompleto;
                  materialList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>

        {/* Etapa ----------------------------------------------------------------------------------------------------------- */}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Etapa Atual
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="etapaIds"
              options={etapaList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.etapaIds &&
                model.etapaIds.map((value) => {
                  let itemCompleto;
                  etapaList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>

        {/* Regional -------------------------------------------------------------------------------------------------------- */}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Regional
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="regionalIds"
              options={regionalList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.regionalIds &&
                model.regionalIds.map((value) => {
                  let itemCompleto;
                  regionalList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>
        {/* Solicitante -------------------------------------------------------------------------------------------------------- */}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Solicitante
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="solicitanteIds"
              options={solicitantesList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.solicitanteIds &&
                model.solicitanteIds.map((value) => {
                  let itemCompleto;
                  solicitantesList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>
        {/* Redes -------------------------------------------------------------------------------------------------------- */}
        <FormGroup className="row">
          <label className="col-md-3 col-form-label text-md-right">
            Rede
          </label>
          <div className="col-md-9">
            <Select
              isMulti
              name="redeIds"
              options={redesList}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Selecione..."
              onChange={(item, name) => onChangeMultiSelect(item, name)}
              value={
                model.redeIds &&
                model.redeIds.map((value) => {
                  let itemCompleto;
                  redesList.filter((itemCategoria) => {
                    const valorEncontrado = itemCategoria.options.find(
                      (opt) => {
                        return value == parseInt(opt.value, 10);
                      }
                    );
                    if (valorEncontrado) itemCompleto = valorEncontrado;
                  });
                  return itemCompleto;
                })
              }
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          {/* Data Criacao Inicio --------------------------------------------------------------------------------------------- */}
          <label className="col-md-3 col-form-label text-md-right">
            Criado em
          </label>
          <div className="col-md-4">
            <DatePicker
              className="filter-data"
              name="dataInicio"
              maxDate={new Date()}
              selected={model.dataInicio}
              onChange={(item, name) => onChangeWithName(item, name)}
            />
          </div>

          {/* Data Criacao Fim ---------------------------------------------------------------------------------------------- */}
          <label className="col-md-1 col-form-label text-md-center">até</label>
          <div className="col-md-4">
            <DatePicker
              className="filter-data"
              name="dataFim"
              disabled={!model.dataInicio}
              minDate={model.dataInicio}
              maxDate={new Date()}
              selected={model.dataFim}
              onChange={onChangeWithName}
            />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <div className="offset-md-3 col-md-9">
            {/* Botao Filtrar ----------------------------------------------------------------------------------------------- */}
            <Button
              className="query-button"
              variant="primary"
              onClick={props.filter}
            >
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> Filtrar
            </Button>
            {/* Botao Limpar ------------------------------------------------------------------------------------------------ */}
            <Button
              className="query-button"
              variant="outline-primary"
              onClick={props.clearfilter}
            >
              <FontAwesomeIcon icon={faEraser}></FontAwesomeIcon> Limpar
            </Button>
          </div>
        </FormGroup>
      </form>
    </div>
  );
};

export default Filter;

/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faDollarSign,
  faPaperPlane,
  faPlus,
  faTimes,
  faCalendar,
  faRulerCombined
} from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import api from 'api';
import { Context } from 'context/AuthContext';
import TitlePages from 'components/_base/TitlePages';
import ModalSummary from 'components/ModalSummary';
import ModalProdutos from './ModalProdutos';
import ModalDataVigencia from './ModalDataVigencia';
import ModalTamanhos from './ModalTamanhos';
import SolicitacaoArquivos from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/SolicitacaoArquivos';
import Historico from 'pages/SolicitacaoFluxo/Status/ContentSolicitacaoFluxoStatus/Historico';
import { dateFormat } from '../../../../helpers/Date.Helper';

import {
  GET_APROVAR_ARTE_MODEL,
  APROVAR_ARTE,
  REPROVAR_ARTE
} from 'services/SolicitacaoFluxo';

const ContentSolicitacaoFluxoAprovarArte = () => {

  const { showLoader } = useContext(Context);

  const paramsLocation = new URLSearchParams(useLocation().search);

  const [solicitacaoId] = useState(
    Number.parseInt(paramsLocation.get('solicitacaoId')) || 0
  );
  const [model, setModel] = useState({});
  const [showComment, setShowComment] = useState(false);
  const [showEnviarAprovacao, setShowEnviarAprovacao] = useState(false);
  const [showModalProdutos, setShowModalProdutos] = useState(false);
  const [showModalDataVigencia, setShowModalDataVigencia] = useState(false);
  const [showModalTamanhos, setShowModalTamanhos] = useState(false);
  const [resumoSolicitacaoId, setResumoSolicitacaoId] = useState(0);
  const [resultModel, setResultModel] = useState({});
  const [layoutProdutosObrigatorios, setLayoutProdutosObrigatorios] = useState([]);
  const [selectListUnidades, setSelectListUnidades] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [produtoCategorias, setProdutoCategorias] = useState([]);
  const [layoutProdutosEspecificos, setLayoutProdutosEspecificos] = useState([]);
  const [regionalProdutosObrigatorios, setRegionalProdutosObrigatorios] = useState([]);

  const dataFim = model.dataVeiculacaoFim;
  const dataInicio = model.dataVeiculacaoInicio;

  useEffect(() => {
    (async () => {
      showLoader(true);

      const responseModel = await GET_APROVAR_ARTE_MODEL(solicitacaoId);
      setModel(responseModel.value);

      showLoader(false);
    })();
  }, []);

  function renderResumoButton() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <div className="text-left">
        <button
          className="btn btn-primary"
          onClick={() => {
            setResumoSolicitacaoId(solicitacaoId);
          }}
        >
          Ver Solicitação
        </button>
      </div>
    );
  }

  function renderHistory() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <hr />
        <Historico solicitacaoId={model.solicitacaoId} />
      </>
    );
  }

  function renderButtons() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return (
      <>
        <button className="m-2 btn btn-success" onClick={aprovar}>
          <FontAwesomeIcon icon={faThumbsUp} /> Aprovar
        </button>
        <button className="m-2 btn btn-danger" onClick={reprovar}>
          <FontAwesomeIcon icon={faThumbsDown} /> Reprovar
        </button>
      </>
    );
  }

  function aprovar() {
    setShowEnviarAprovacao(true);
    setShowComment(false);
  }

  function enviarAprovacao() {
    showLoader(true);
    let aprovacaoModel = {...model};
    aprovacaoModel.dataVeiculacaoInicio = null;
    aprovacaoModel.dataVeiculacaoFim = null;
    aprovacaoModel.solicitacaoProdutos = null;
    aprovacaoModel.solicitacaoTamanhos = null;
    console.log(aprovacaoModel);
    console.log(model);
    (async () => {
      const response = await APROVAR_ARTE(aprovacaoModel);

      if (response.value) {
        setModel({});
      }
      setResultModel(response);
      showLoader(false);

      window.scrollTo(0, 0);
    })();
  }

  function reprovar() {
    setShowComment(true);
    setShowEnviarAprovacao(false);
  }

  function enviarReprovacao() {
    showLoader(true);    
    //model.arquivosComplementarFluxo = arquivoComplementarFluxo;
    (async () => {
      // if ( model.solicitacaoProdutos &&  model.solicitacaoProdutos.length > 0){
      //   //para tentar parar de quebrar o form-data com algumas props desses objetos ciclicas
      //   model.solicitacaoProdutos.forEach(solicitacaoProduto => {
      //     solicitacaoProduto.produto = null;
      //     solicitacaoProduto.solicitacaoProdutoDetalhamentos = null;
      //   });
      // }

      const response = await REPROVAR_ARTE(model);

      //value == true => alterado com sucesso
      if (response.value) {
        setModel({});
      }

      setResultModel(response);

      showLoader(false);
    })();
  }

  function renderArquivos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    return <SolicitacaoArquivos solicitacaoId={model.solicitacaoId} />;
  }

  function renderEnviarAprovacao() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showEnviarAprovacao) return null;

    return (
      <>
        <hr />
        <div className="row text-center">
          <div className="col-lg-12">
            <h4>
              ATENÇÃO: Confira as descrições, os preços e unidades de vendas de
              TODOS OS PRODUTOS, para ter certeza que as informações estão de
              acordo. Aprovando o layout, você está concordando com TODAS as
              informações constantes na arte. {model.arquivos ? "Para aprovar, anexe abaixo o arquivo da aprovação do cliente da arte." : ""}
              <br />
              <br></br>
              {
                model.solicitacaoComVigencia &&
                <>
                  Verifique a data de Vigência da arte:
                  <br></br>
                  <b>Data Inicial da Vigência do Materia: {dateFormat(dataInicio, false)} </b>
                  <br></br>
                  <b>Data Final da Vigência do Materia: {dateFormat(dataFim, false)} </b>
                  <br />
                  <br></br>
                </>
              }
              Caso tenha alguma correção a ser feita, clique em REPROVAR
            </h4>
          </div>

          {
            model.arquivos && (
              <div className="col-lg-12 mt-2">
                {/* <div className="mb-3">
                  <label type="button" className="btn btn-primary">
                    <input
                      type="file"
                      className="d-none"
                      onChange={(e) => {
                        setModel({ ...model, files: [...(model.files || []), ...e.target.files] });
                        e.target.value = null;
                      }}
                    />
                    <FontAwesomeIcon icon={faPlus} /> Adicionar Arquivo
                  </label>
                </div> */}


                {
                  (model.files || []).map((file, index) => (
                    <div key={index} className="mb-2">
                      {file.name} <button type="button" className="btn btn-link" onClick={() => {
                        model.files.splice(index, 1);
                        setModel({ ...model });
                      }}><FontAwesomeIcon icon={faTimes} /></button>
                    </div>
                  ))
                }
              </div>
            )
          }



          <div className="col-lg-12 mt-2">
            <button className="btn btn-primary" onClick={enviarAprovacao}>
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderComment() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showComment) return null;

    return (
      <>
        <hr />
        <div className="row">
          <div className="col-lg-9">
            <textarea
              rows="7"
              placeholder='Descreva aqui o motivo da reprova da arte e quais alterações deseja que sejam feitas.'
              className="form-control"
              value={model.observacao}
              onChange={(e) =>
                setModel({ ...model, observacao: e.target.value })
              }
            ></textarea>
          </div>
          <div className="col-lg-3">
            {/* Vigencia --------------------------------------------------------------------------------------------------- */}
            {model.solicitacaoComVigencia &&
              <button
                className="btn btn-primary btn-alteracoes w-100 mb-3"
                onClick={() => {
                  setShowModalDataVigencia(true);
                }}
              >
                <FontAwesomeIcon icon={faCalendar} /> Alterar Vigência
              </button>
            }
            {/* Tamanhos --------------------------------------------------------------------------------------------------- */}
            {model.solicitacaoTamanhos && model.solicitacaoTamanhos.length > 0 &&
              <button
                className="btn btn-primary btn-alteracoes w-100 mb-3"
                onClick={() => {
                  setShowModalTamanhos(true);
                }}
              >
                <FontAwesomeIcon icon={faRulerCombined} /> Alterar Tamanhos
              </button>
            }
            {/* Produtos --------------------------------------------------------------------------------------------------- */}
            {model.solicitacaoProdutos && model.solicitacaoProdutos.length > 0 &&
              <button
                className="btn btn-alteracoes btn-primary w-100 mb-3"
                onClick={() => {
                  setShowModalProdutos(true);
                }}
              >
                <FontAwesomeIcon icon={faDollarSign} /> Alterar Produtos
              </button>
            }
            {/* Botao Enviar ----------------------------------------------------------------------------------------------- */}
            <button
              className="btn btn-primary btn-alteracoes w-100"
              onClick={enviarReprovacao}
            >
              <FontAwesomeIcon icon={faPaperPlane} /> Enviar
            </button>
          </div>
        </div>
      </>
    );
  }

  function renderModalDataVigencia() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showModalDataVigencia) return null;

    return (
      <ModalDataVigencia
        model={model}
        show={showModalDataVigencia}
        setModel={(model) => {
          setModel({ ...model })
        }}
        dataVeiculacaoInicio={new Date(model.dataVeiculacaoInicio)}
        dataVeiculacaoFim={new Date(model.dataVeiculacaoFim)}
        closeModal={() => setShowModalDataVigencia(false)}
      />
    );
  }

  function renderModalTamanhos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showModalTamanhos) return null;

    return (
      <ModalTamanhos
        tamanhos={model.solicitacaoTamanhos}
        show={showModalTamanhos}
        updateTamanhos={(tamanhos) => {
          model.solicitacaoTamanhos = tamanhos;
          setModel({ ...model });
        }}
        materialUnidadeMedida={model.materialUnidadeMedida}
        closeModal={() => setShowModalTamanhos(false)}
      />
    );
  }

  function renderModalProdutos() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!showModalProdutos) return null;

    return (
      <ModalProdutos
        produtos={model.solicitacaoProdutos}
        show={showModalProdutos}
        updateProdutos={(produtos) => {
          model.solicitacaoProdutos = produtos;
        }}
        closeModal={() => setShowModalProdutos(false)}
        maximoProdutos={model.maximoProdutos}
        minimoProdutos={model.minimoProdutos}
        validaProdutoObrigatorioRegional={model.validaProdutoObrigatorioRegional}
        lojaId={model.lojaId}
        layoutArteId={model.layoutArteId}
      />
    );
  }

  function renderProdutosAlterados() {
    if (!model.solicitacaoId || model.solicitacaoId === 0) return null;

    if (!model.solicitacaoProdutos || model.solicitacaoProdutos.length === 0)
      return null;

    let produtosAlterados = model.solicitacaoProdutos.filter(
      (produto) =>
        produto.novoPreco &&
        produto.novoPreco.length > 0 &&
        parseFloat(produto.novoPreco.replace(',', '.'))
    );
    if (produtosAlterados.length === 0) return null;

    return (
      <>
        <hr />
        <div className="text-left">
          <table className="table-precos-alterados">
            <thead>
              <tr>
                <th>Produto</th>
                <th>Novo Preço</th>
              </tr>
            </thead>
            <tbody>
              {produtosAlterados.map((produto, index) => {
                return (
                  <tr key={index}>
                    <td>{produto.nome}</td>
                    <td>R$ {produto.novoPreco}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  function renderModalSummary() {
    if (resumoSolicitacaoId === 0) return null;

    return (
      <ModalSummary
        solicitacaoId={resumoSolicitacaoId}
        onClose={() => {
          setResumoSolicitacaoId(0);
        }}
      />
    );
  }

  function renderErrors() {
    if (!resultModel.errors || !resultModel.errors.length) return null;

    return (
      <div className="alert alert-danger">
        {resultModel.errors.map((error, index) => {
          return <div key={index}>{error}</div>;
        })}
      </div>
    );
  }

  function renderSuccess() {
    if (!resultModel.message || !resultModel.message.length) return null;

    return (
      <div className="alert alert-success">
        {resultModel.message} <a href="/pendencias">Ir para Pendencias</a>
      </div>
    );
  }

  return (
    <div className="wrapper-content-solicitacaofluxo-aprovar-arte">
      <TitlePages
        title={
          'Solicitação ' +
          solicitacaoId +
          (model && model.solicitacaoId && model.solicitacaoId > 0
            ? ' - Arte aguardando aprovação.'
            : '')
        }
      />

      <div className="content-solicitacaofluxo text-center">
        {renderErrors()}

        {renderSuccess()}

        {renderResumoButton()}

        {renderArquivos()}

        {renderButtons()}

        {renderEnviarAprovacao()}

        {renderProdutosAlterados()}

        {renderComment()}

        {renderHistory()}

        {renderModalDataVigencia()}

        {renderModalTamanhos()}

        {renderModalProdutos()}

        {renderModalSummary()}
      </div>
    </div>
  );
};

export default ContentSolicitacaoFluxoAprovarArte;

import '../styles.scss';
import React from 'react';
import { formatarMoeda } from 'util/numberUtil';
import SemDados from '../SemDados';

const RegionalVerbaMensal = (props) => {
  const { data, dataLabels } = props;
  const titulo = 'VERBA UTILIZADA POR REGIONAL - MÊS A MÊS';

  if (!data || data.length < 1) {
    return <SemDados titulo={titulo} />;
  }

  const itens = data.map((item) => {
    const str = item.nome;
    const mes = str.substring(0, 3);
    const ano = str.substring(str.indexOf('-') + 1).trim();
    return `${mes} - ${ano}`;
  });

  const regionais = dataLabels.map((item) => {
    return data.map((element) => {
      let valorSolicitacoes = 0;
      element.regional.forEach((regional) => {
        if (regional.nome === item) {
          valorSolicitacoes = regional.totalSolicitacao;
        }
      });
      return valorSolicitacoes;
    });
  });

  return (
    <div className="grafico" style={{ overflowY: 'hidden' }}>
      <div className="grafico-titulo">
        <span>{titulo}</span>
      </div>
      <div className="tabela-container">
        <table className="tabela">
          <thead>
            <tr key="rh">
              <th
                className="fixed-header fixed-col-1"
                style={{ width: '250px' }}
                key="h"
              >
                Regional
              </th>
              {itens.map((item) => {
                return (
                  <th
                    className="fixed-header"
                    style={{ width: `100px` }}
                    key={`h${item}`}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="celula-total fixed-col-1" key="cn">
                Total Brasil
              </td>
              {itens.map((item, index) => {
                const valores = regionais.map((element) => {
                  return element[index];
                });
                return valores.reduce((a, b) => a + b, 0) === 0 ? (
                  <td className="celula-total" key={`c${item}`} />
                ) : (
                  <td className="celula-total" key={`c${item}`}>
                    R$
                    {valores.reduce((a, b) => a + b, 0).toLocaleString('pt-BR')}
                  </td>
                );
              })}
            </tr>
            {dataLabels.map((item, index) => {
              return (
                <tr key={`r${item}`}>
                  <td key={`cn${item}`} className="fixed-col-1">
                    {item}
                  </td>
                  {regionais[index].map((element, index2) => {
                    return (
                      <td key={`c${item}${itens[index2]}`}>
                        {element === 0 ? '-' : `R$ ${formatarMoeda(element)}`}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RegionalVerbaMensal;
